import React, { useState } from "react"
import { FaTimes } from "react-icons/fa";
import { SiUbereats, SiDeliveroo } from "react-icons/si";
import Modal from 'react-modal';


export default function Shortcut({ data, size }) {
  const [iframe, showIframe] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '200%',
      height: '200%',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    }
  };
  const iconSize = size === 'small' ? "0.8em" : "2em"
  return (
    <div className="shortcut flex flex-row items-center justify-center space-x-4">
      {data.restaurant.urlName && data.restaurant.websiteSettings?.reservation && (
        <div role="button" tabIndex={0} onClick={() => showIframe(!iframe)} className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/event.png" /></div>
          <div className="text-black">Réservation</div>
        </div>
      )}

      {data.restaurant.settings?.delivery && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/delivery.png" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.settings?.takeaway && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/take-away.png" /></div>
          <div className="text-black">Click&Collect</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.uberEatsUrl && (
        <a href={`${data.restaurant.websiteSettings?.uberEatsUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><SiUbereats size={iconSize} color="black" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.deliverooUrl && (
        <a href={`${data.restaurant.websiteSettings?.deliverooUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><SiDeliveroo size={iconSize} color="black" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.googleMapsUrl && (!data.restaurant.websiteSettings?.uberEatsUrl && !data.restaurant.websiteSettings?.deliverooUrl) && (
        <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/location.png" /></div>
          <div className="text-black">Itinéraire</div>
        </a>
      )}

      <a href={`tel:${data.restaurant.informations.phone}`} className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
        <div className="rounded-full h-8 w-8"><img src="/assets/call.png" /></div>
        <div className="text-black">Appeler</div>
      </a>

      <Modal
        isOpen={iframe}
        onRequestClose={() => showIframe(!iframe)}
        style={customStyles}
        contentLabel="Reservation"
      >
        <iframe src={`https://reservation.we-eats.com/${data.restaurant.urlName}`} width="200%" height="200%" frameBorder="0" title="réservation" />
        <span className="close-btn" tabIndex={0} onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button">
          <FaTimes color="white" size="1.8em" />
        </span>

      </Modal>
    </div>
  )
}
